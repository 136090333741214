import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from  '@angular/common/http';
import { catchError } from 'rxjs/operators'; 
import { environment } from '@environments/environment'
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public allowedMergeFiles: String[] = ["3fr",
    "abw", "ai", "arw", "azw", "azw3", "azw4", "bmp", "cbc",
    "cbr", "cbz", "cdr", "cgm", "chm", "cr2", "crw", "csv", "dcr", "djvu", "dng", "doc",
    "docm", "docx", "dot", "dotx", "dps", "dwg", "dxf", "emf", "eps", "epub", "erf",
    "et", "fb2", "gif", "heic", "htm", "html", "htmlz", "hwp", "ico", "jpeg", "jpg", "key",
    "lit", "lrf", "lwp", "md", "mobi", "mos", "mrw", "nef", "numbers", "odd", "odp",
    "ods", "odt", "orf", "pages", "pdb", "pdf", "pef", "pml", "png", "pot", "potx", "ppm",
    "pps", "ppsx", "ppt", "pptm", "pptx", "prc", "ps", "psd", "raf", "raw", "rb", "rst", "rtf",
    "sda", "sdc", "sdw", "sk", "sk1", "snb", "svg", "svgz", "tcr", "tex", "tif", "tiff",
    "txt", "txtz", "vsd", "webp", "wmf", "wpd", "wps", "x3f", "xcf", "xls", "xlsm", "xlsx", "xps", "zabw"]

  public allowedConvertFiles: String[] = ['dxf', 'doc', "docx", 'html', 'rtf', "pdf", "txt", "azw3", "epub",
      "lrf", "mobi", "pdb", "bmp", "eps", "ico", "jpg", 'gif', "odd", "png", "ps", "psd", "tiff", "webp",
      "oeb", "emf", "svg", "wmf"]
  constructor(private httpClient: HttpClient) {}

  initConvert(files: File[]) {
    const response = {error: null, subscribtion: null, rejected: []};

    switch(true) {
      case files.length === 1:
        const ext = files[0].name.split('.').pop()
        if(this.allowedConvertFiles.includes(ext)) {
          response.subscribtion = this.convertFile(files[0])
        } else {
          response.error = 'alert'
          response.rejected.push(files[0])
        }
        break;
      case files.length > 1:
        const filtered: Array<File> = [];
        Object.values(files).forEach((value:File, index)=> {
          if(this.allowedMergeFiles.includes(value.name.split('.').pop())) {
            filtered.push(value)
          } else {
            response.rejected.push(value)
          }
        });

        if(filtered.length > 0) {
          if(filtered.length == 1) {
            const ext = filtered[0].name.split('.').pop()
            if(this.allowedConvertFiles.includes(ext)) {
              response.subscribtion = this.convertFile(files[0])
            } else {
              response.error = 'alert'
              response.rejected.push(files[0])
            }
          } else {
            response.subscribtion = this.mergeToPdf(filtered)
          }
        } else {
          response.error = 'alert'
        }
        break;
      default:
        response.error = 'alert'
        break;
    }
    return response
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string;

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //return throwError(errorMessage);
    return errorMessage
  }

  private mergeToPdf(files: any) {
    // console.log("mergeToPdf");

    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });
    return this.httpClient
    .post<any>(environment.service_url + environment.path.merge + files.length, formData)
    .pipe(catchError(this.handleError));
  }

  private convertFile(file:File) {
    let to_type = "pdf"
    let formData: FormData = new FormData();
    formData.append(`file`, file, file.name);

    if(file.type == 'application/pdf') {
      to_type = "doc"
    }
    
    return this.httpClient
    .post<any>(environment.service_url + environment.path.convert + to_type, formData)
    .pipe(catchError(this.handleError));
  }
}