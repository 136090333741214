import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { WindowService } from './window.service';


@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
   private window; 

   constructor (private _window: WindowService)
   {
       this.window = _window.nativeWindow; // intialise the window to what we get from our window service

   }

    private pushDataLayer(obj)
    {
        console.log(obj)
        if(obj && !environment.extension)  this.window.dataLayer.push(obj);
    }
   
   generatePageView(url)
   {
       const hit = {
           event: 'content-view',
           pageName: url
       };
       this.pushDataLayer(hit);
   }

   generateEvent(eventType:String, eventCategory:String, eventAction:String, eventLabel:String)
   {
       const hit = {
           event:eventType,
           eventCategory:eventCategory,
           eventAction:eventAction,
           eventLabel: eventLabel
       }
        this.pushDataLayer(hit);
   }
}