<div class="sticky-header mobile-hidden">
  <app-header></app-header>
  <!-- <section class="ads-top">
    <div class="container">
      <div class="row middle-xs">
        <div class="col-sm-2">
          <p class="ads-top__title">Ads: </p>
        </div>
        <div class="col-sm-10">
          <ul>
            <li><a (click)="onClick($event, 'adobe_pdf_editor')" href="#">Adobe PDF Editor</a></li>
            <li><a (click)="onClick($event, 'free_online_pdf')" href="#">Free Online PDF</a></li>
            <li><a (click)="onClick($event, 'png_to_pdf')" href="#">PNG to PDF</a></li>
            <li><a (click)="onClick($event, 'pdf_editor')" href="#">PDF Editor</a></li>
            <li><a (click)="onClick($event, 'jpg_to_pdf')" href="#">JPG to PDF</a></li>
            <li><a (click)="onClick($event, 'free_pdf_editor')" href="#">Free PDF Editor</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section> -->
</div>
<section class="title-wrapper text-center mobile-hidden ">
    <div class="container">
    <h1 class="title">PDF Converter</h1>
    <p class="subtitle">Convert files from and to PDF</p>
    </div>
</section>
<app-converter></app-converter>
<section class="title-wrapper text-center mobile-hidden ">
  <div class="container">
    <h1 class="title">The Best Online Word to PDF Converter</h1>
    <p class="subtitle">PDF HUB is the most popular online PDF software. We help
      millions of happy users to convert Word files to the handy PDF
      format every day. Try it out!</p>
  </div>
</section>
<section class="steps mobile-hidden ">
  <div class="container">
    <div class="row column-list between-xs">
      <div class="col column-ads">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="4998786717"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="true"
          [width]="300"
          [height]="600"
        ></ng-adsense>
      </div>
      <div class="col column-center">
        <div class="row middle-xs">
          <div class="col-sm-8">
            <h3 class="title-xs">Seamless Word to PDF Conversion</h3>
            <p>With this helpful tool, you can efficiently and accurately transform any Word
              document to PDF online. Same layout, same formatting.</p>
          </div>
          <div class="col-sm-4">
            <img src="/images/step1.svg" alt="Seamless Word to PDF Conversion">
          </div>
        </div>
        <div class="row middle-xs">
          <div class="col-sm-4">
            <img src="/images/step2.svg" alt="Share Converted Files with Others">
          </div>
          <div class="col-sm-8">
            <h3 class="title-xs">Share Converted Files with Others</h3>
            <p>Once you convert a Word file to PDF—use our share feature to quickly create a
              shareable download link or email the PDF via a simple click.</p>
          </div>
        </div>
        <div class="row middle-xs">
          <div class="col-sm-8">
            <h3 class="title-xs">Enhance Your PDFs</h3>
            <p>Before saving, take advantage of our other tools to modify your PDF further. You can add text and annotations,
              compress it for emailing, or even merge it with other PDFs for easy file handling.</p>
          </div>
          <div class="col-sm-4">
            <img src="/images/step3.svg" alt="Enhance Your PDFs">
          </div>
        </div>
      </div>
      <div class="col column-ads text-right">
        <ng-adsense
          [adClient]="'ca-pub-6495373596935145'"
          [adSlot]="6807488561"
          [display]="'block'"
          [adFormat]="'auto'"
          [fullWidthResponsive]="true"
          [width]="300"
          [height]="600"
        ></ng-adsense>
      </div>
    </div>
  </div>
</section>
<section class="about text-center mobile-hidden ">
  <div class="container">
    <div class="row column-list between-xs">
      <div class="col column-ads">
        <!-- <img src="/images/ads1.png" alt="ads" /> -->
      </div>
      <div class="col column-center">
        <h2 class="title">Why Choose PDF Hub Online Converter</h2>
        <div class="row about-list">
          <div class="col-sm-4">
            <div class="about-img">
              <img src="/images/hours.svg" alt="24/7 Anywhere Access">
            </div>
            <p class="about-title">24/7 Anywhere Access</p>
            <p>At home? On the road? Our PDF Combiner makes it a cinch to join multiple files into one single document from
              both mobile and desktop devices. Compatible with all web browsers and with Microsoft Office, Google Drive, and Dropbox.</p>
          </div>
          <div class="col-sm-4">
            <div class="about-img">
              <img src="/images/help.svg" alt="Customer Support">
            </div>
            <p class="about-title">Customer Support</p>
            <p>Our users enjoy convenient and quality customer service. First of all, you can find a lot of answers
              and guides on our blog.
              Beyond that, we are available in our email box for questions and support.</p>
          </div>
          <div class="col-sm-4">
            <div class="about-img">
              <img src="/images/downloads.svg" alt="Free Downloads">
            </div>
            <p class="about-title">Free Downloads</p>
            <p>Our users have the ability to download as many files as they want. Yes yes, you heard right, the amount
              of downloads is unlimited, and it's free! You can convert PDD file to DOC files in seconds.</p>
          </div>
        </div>
      </div>
      <div class="col column-ads text-right">
        <!-- <img src="/images/ads2.png" alt="ads" /> -->
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>