import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataLayerService } from '@services/data-layer.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PDF Hub';
 
  constructor(
    private titleService: Title,
    private dataLayerService: DataLayerService,
  ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {}

  onClick(event, category) {
    this.dataLayerService.generateEvent('ga_event','header_ads_link', category + '_click', null);
    event.preventDefault();
    // this.modal.status = true
    window.open(
      `https://searc.me/03289e8f-6d50-4ad1-806a-2a0708038a5d?q=${category}&chname=cel_PDFHUB1`,
      '_blank'
    );
  }
}
